import axios from "axios"

export default axios.create({
  baseURL: process.env.GATSBY_API_URL,
  headers: {
    tenant_token: process.env.GATSBY_API_TOKEN,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})
